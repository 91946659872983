<template>
  <div class="hovering__pad py-3">
    <div class="px-3 mb-3 d-flex">
      <b-input class="mr-4" v-model="name"></b-input>
      <b-button @click="generateCode" class="mr-3" variant="outline-default"
        >Сгенерировать</b-button
      >
    </div>
    <b-table v-if="apiKeys" :fields="fields" :items="apiKeys" stacked="sm" responsive>
      <template #cell(_id)="data">{{ data.value.$oid }}</template>
      <template #cell(key)="data"
        ><b-button
          variant="link"
          :href="`${window.location.origin}${window.location.pathname}#/education/tests?api_key=${data.item.key}`"
          >{{
            `${window.location.origin}${window.location.pathname}#/education/tests?api_key=${data.item.key}`
          }}</b-button
        ></template
      >
      <template #cell(delete)="data">
        <b-button
          style="height: 33.5px"
          variant="outline-default"
          @click="revokeCode(data.item.key)"
          ><img style="width: 18px" src="@main/assets/img/svg/redcross.svg" alt="" /></b-button
      ></template>
    </b-table>
    <loader v-else></loader>
  </div>
</template>

<script>
import education from '@main/api/education';
import Loader from '../Loader.vue';

export default {
  components: { Loader },
  data: () => ({
    name: '',
    loading: false,
    apiKeys: null,
  }),
  computed: {
    fields() {
      return [
        { key: '_id', label: 'ID' },
        { key: 'name', label: 'Имя' },
        { key: 'key', label: 'Ключ' },
        { key: 'delete', label: 'Удалить' },
      ];
    },
  },
  methods: {
    async updateData() {
      this.loading = true;
      try {
        const result = await education.auth.apiKey.list();
        this.apiKeys = result.data_array;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async generateCode() {
      const result = await education.auth.apiKey.get(this.name);
      await this.updateData();
    },
    async revokeCode(key) {
      await education.auth.apiKey.revoke(key);
      await this.updateData();
    },
  },
  async mounted() {
    await this.updateData();
  },
};
</script>
